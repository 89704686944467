





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsCmsCat12 extends Vue {
  @Prop() private cmsCategory!: object;
}
